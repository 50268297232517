import { Button } from '@/components/Elements';
import { useAuth } from '@/lib/auth';

import { Layout } from '../components/Layout';

export const Finished = () => {
  const { logout, isLoggingOut } = useAuth();
  const handleSignOut = () => {
    logout();
  };
  return (
    <Layout withSignOut={false}>
      <div className="text-center">
        <h3 className="mt-6 text-sm font-medium text-gray-900">Thank you!</h3>
        <p className="mt-1 text-sm text-gray-500">
          We will review your information and be in touch with you soon on next steps to complete
          your onboarding.
        </p>
        <p className="mt-1 text-sm text-gray-500">
          In the meantime, please reach out to us at international-support@justworks.com if you have
          any questions.
        </p>
        <div className="mt-6">
          <Button
            isLoading={isLoggingOut}
            disabled={isLoggingOut}
            variant="secondary"
            size="lg"
            onClick={handleSignOut}
          >
            Sign out
          </Button>
        </div>
      </div>
    </Layout>
  );
};
