import { Layout } from '../components/Layout';

export const EmailSent = () => (
  <Layout title="Thanks! Please check the link in your email to access your Via Dashboard">
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white px-4 py-8 shadow sm:rounded-3xl sm:px-10">
        <div>
          <p>
            If you’re having trouble, please check your spam folder or email us at
            international-support@justworks.com.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);
